<template>
  <div>
    <b-row>
      <b-col>
        <b-progress v-if="countdown" style="position:fixed;top:0;left:0;width:100%;z-index:9999" height="5px" variant="success" :max="maxcountdown" :value="countdown" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card v-if="booking" title="Book a unit">
          <form @submit.prevent="bookUnit">
            <h3>{{ item.name }}</h3>
            <date-range v-model="booking.range" past-disabled />
            <b-form-group v-if="role !== 'USER'" label-cols-sm="4" label-cols-lg="3" label="Price" label-for="price" description="Without taxes">
              <b-row>
                <b-col>
                  <b-form-input id="price" v-model="booking.price" step="0.01" :state="state('price')" type="number" placeholder="Price" />
                </b-col><b-col>
                  <b-form-select v-model="booking.priceMethod" :state="state('priceMethod')">
                    <option value="DAY">
                      Per-day
                    </option>
                    <option value="MONTH">
                      Per-month
                    </option>
                    <option value="MONTH_PER_M2">
                      Per-month per m2
                    </option>
                  </b-form-select>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group v-if="role !== 'USER'" label-cols-sm="4" label-cols-lg="3" label="Owner cloud user">
              <blueprint-search-input
                id="user"
                v-model="booking.ownerId"
                :custom-label="customUserLabel"
                placeholder="Type to search"
                api-route-path="users"
                @input="setFirstLastName"
              />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Firstname">
              <b-form-input id="firstname" v-model="booking.firstname" :state="state('firstname')" type="text" placeholder="Firstname" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Lastname">
              <b-form-input id="lastname" v-model="booking.lastname" :state="state('lastname')" type="text" placeholder="Lastname" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Document No.">
              <b-form-input id="documentno" v-model="booking.documentno" :state="state('documentno')" type="text" placeholder="Document No." />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Birthday">
              <b-form-input id="birthday" v-model="booking.birthday" :state="state('birthday')" type="text" placeholder="YYYY-MM-DD" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Invoices e-mail">
              <b-form-input id="invoicesEmail" v-model="booking.invoicesEmail" :state="state('invoicesEmail')" type="text" placeholder="Invoices email address" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Company" label-for="company">
              <blueprint-search-input
                id="company"
                v-model="booking.companyId"
                :state="state('companyId')"
                placeholder="Type to search"
                api-route-path="companies"
                @input="setAddress"
              />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Street">
              <b-form-input id="street" v-model="booking.street" :state="state('street')" type="text" placeholder="Street" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="City">
              <b-form-input id="city" v-model="booking.city" :state="state('city')" type="text" placeholder="City" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Zip code">
              <b-form-input id="zip" v-model="booking.zip" :state="state('zip')" type="text" placeholder="Zip" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="State">
              <b-form-input id="state" v-model="booking.state" :state="state('state')" type="text" placeholder="State" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Country">
              <b-form-input id="country" v-model="booking.country" :formatter="toUpperCase" :state="state('country')" type="text" placeholder="ISO 3166 Country Code" />
            </b-form-group>
            <b-button type="submit" variant="success">
              Book
            </b-button>
            <b-button type="button" variant="dark" @click.prevent="back">
              Cancel <span v-if="countdown">and unlock unit</span>
            </b-button>
          </form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DateRange from '../../../components/date-range.vue';

const LOCK_TIMEOUT = 10; // minutes
// IDEA: use vutex/store for booking/lock process to keep a track for bookings and locked access tokens
// add a component to display all locked units and booking processes and remaining time on all routes.
export default {
  components: {
    DateRange,
  },
  filters: {
    timeInHours (value) {
      const hours = parseInt(Math.floor(value / 3600), 10);
      const minutes = parseInt(Math.floor((value - (hours * 3600)) / 60), 10);
      const seconds = parseInt((value - ((hours * 3600) + (minutes * 60))) % 60, 10);

      const dHours = (hours > 9 ? hours : `0${hours}`);
      const dMins = (minutes > 9 ? minutes : `0${minutes}`);
      const dSecs = (seconds > 9 ? seconds : `0${seconds}`);

      return `${dHours}:${dMins}:${dSecs}`;
    },
  },
  data () {
    return {
      item: {},
      booking: undefined,
      errors: {},
      token: undefined,
      interval: undefined,
      countdown: 0,
      maxcountdown: (LOCK_TIMEOUT * 60),
    };
  },
  computed: {
    me () {
      return this.$store.getters.me;
    },
    role () {
      return this.$store.getters.me ? this.$store.getters.me.role : 'USER';
    },
  },
  // eslint-disable-next-line consistent-return
  beforeRouteLeave (to, from, next) {
    if (!this.countdown) {return next();}
    this.$bvModal.msgBoxConfirm('Do you really want to leave? You have unsaved changes!', {
      size: 'sm',
      okVariant: 'danger',
    }).then((val) => {
      if (val) {
        clearInterval(this.interval);
        this.unlockUnit().then(() => {
          next();
        });
      } else {
        return next(false);
      }
    });
  },
  async mounted () {
    const loader = this.$loading.show();
    if (!this.$store.getters.me) {
      await this.$store.dispatch('FETCH_ME');
    }
    await this.getUnit();
    await this.lockUnit();
    let { start, end } = this.$route.query;
    const booking = {};
    if (start || end) {
      start = new Date(start);
      end = new Date(end);
      booking.range = { start, end };
    }
    if (this.me && this.role === 'USER') {
      booking.firstname = this.me.firstname;
      booking.lastname = this.me.lastname;
      booking.ownerId = this.me.id;
    } else {
      booking.price = this.item.price;
      booking.priceMethod = this.item.priceMethod;
    }
    this.booking = booking;
    loader.hide();
  },
  methods: {
    setFirstLastName () {
      if (this.booking.ownerId) {
        this.$http.get(`users/${this.booking.ownerId}`)
          .then(({ data }) => {
            this.$set(this.booking, 'firstname', data.firstname || '');
            this.$set(this.booking, 'lastname', data.lastname || '');
          });
      } else {
        this.$set(this.booking, 'firstname', '');
        this.$set(this.booking, 'lastname', '');
      }
    },
    setAddress () {
      if (this.booking.companyId) {
        this.$http.get(`companies/${this.booking.companyId}`)
          .then(({ data }) => {
            this.$set(this.booking, 'street', data.street || '');
            this.$set(this.booking, 'city', data.city || '');
            this.$set(this.booking, 'state', data.state || '');
            this.$set(this.booking, 'zip', data.zip || '');
            this.$set(this.booking, 'country', data.country || '');
          });
      } else {
        this.$set(this.booking, 'street', '');
        this.$set(this.booking, 'city', '');
        this.$set(this.booking, 'state', '');
        this.$set(this.booking, 'zip', '');
        this.$set(this.booking, 'country', '');
      }
    },
    customUserLabel (item) {
      const fields = [];
      if (item.firstname) {
        fields.push(item.firstname);
      }
      if (item.lastname) {
        fields.push(item.lastname);
      }
      if (item.email) {
        fields.push(item.email);
      }
      if (item.phone) {
        fields.push(item.phone);
      }
      return fields.join(' ');
    },

    back () {
      this.$router.go(-1);
    },
    toUpperCase (value) {
      return value.toUpperCase();
    },
    state (item) {
      return (this.errors[item] ? false : null);
    },
    unlockUnit () {
      this.countdown = 0;
      if (this.token) {
        const { id } = this.$route.params;
        return this.$http.post(`bookings/units/${id}/unlock?booking_token=${this.token}`, {})
          .catch(this.err);
      }
      return Promise.resolve();
    },
    lockUnit () {
      this.$bvModal.msgBoxConfirm('Do you want to lock a unit for booking process?', {
        size: 'sm',
        okVariant: 'danger',
      }).then((val) => {
        if (!val) {
          return;
        }
        const { id } = this.$route.params;
        this.countdown = 0;
        this.$http.post(`bookings/units/${id}/lock`, { minutes: LOCK_TIMEOUT })
          .then((res) => {
            this.token = res.data.booking_token;
            this.countdown = LOCK_TIMEOUT * 60;
            this.interval = setInterval(() => {
              if (!this.countdown) {
                clearInterval(this.interval);
                this.lockUnit();
                return;
              }
              this.countdown -= 1;
            }, 1000);
          })
          .catch(this.err);
      });
    },
    getUnit () {
      const { id } = this.$route.params;
      return this.$http.get(`bookings/units/${id}`)
        .then((res) => {
          this.item = res.data;
          if (this.item.locked === true) {
            throw new Error('Unit is locked');
          }
        })
        .catch(this.err);
    },
    bookUnit () {
      const { id } = this.$route.params;
      const { booking } = this;
      this.$http.post(`bookings/units/${id}/booking?booking_token=${this.token}`, booking)
        .then((res) => {
          this.item = res.data;
          this.countdown = 0;
          clearInterval(this.interval);
          this.success('Success', 'Unit is booked');
          this.$router.go(-1); // TODO: redirect to booking
        })
        .catch(this.err);
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    success (title, text) {
      this.$notify({
        title,
        text,
        type: 'success',
      });
    },

  },
};
</script>
